import { FilterInput, SortDirection } from "../../graphql/graphql";


export type TableFilter = {
  page: number;
  size: number;
  sort: string;
  direction: SortDirection;
  filters?: FilterInput[];
}

export const defaultTableFilter = {
  page: 0,
  size: 50,
  sort: 'id',
  direction: 'ASC' as SortDirection,
}